.all-notes {
  font-family: "Coda Caption", sans-serif;
  border: 2px solid #7cb317;
  overflow-x: auto;
  
  margin-left: auto;
  margin-right: auto;
  background: #f4d8cd;
  border: 5px solid #f4d8cd;
  /* box-shadow: 4px 5px 7px 5px black; */
  box-shadow: 3px 3px 3px 3px gray;
  margin-bottom: 50px;
  border-radius: 200px;
  width: 80vw;
  padding:50px;
  
}

.note_contents {
  margin-left:auto;
  margin-right:auto;

  text-align: center;
}
.fa-plus-square{
  margin-right:10px;
}

.fa-trash-alt{
  
    color:red;
  padding:0px;
  margin-right:15px;
  
  
}
.fa-trash-alt:hover{
  transform: scale(1.3);
}

.all-topics {
  padding-top: 30px;
  padding-bottom: 30px;
  /* padding-top: 30px; */
  
  background: #f4d8cd;
  font-family: "Coda Caption", sans-serif;
  border: 5px solid #f4d8cd;
  box-shadow: 2px 2px 5px 5px gray;
  /* width: 45%; */
  width:60vw;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  border-radius: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

@media all and (min-width:768px){
  .all-topics{
    width:50vw;
  }
  .all-notes{
    width:40vw;
  }
}

.topics-heading{
  font-weight: 400;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  font-family: "Coda Caption", sans-serif;
  color: #1e555c;
}


.note-form {
  background: #f4d8cd;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  border: 1px solid white;
  border-radius: 200px;
  box-shadow: 2px 2px 5px 5px gray;
  margin-left: auto;
  margin-right: auto;
  margin-bottom:30px;
  /* width: 50%; */
  /* margin-top: 50px; */
  text-align: center;
  padding: 30px;
width:70vw;

  /* height: 4vh; */
  /* width:70vw; */
}



@media(min-width:768px){
  /* .note-form {
    
  color:blue;
    height: 70%;
    width:50%;
  } */
}

/* 
.note-form input,
select{
  margin-left: auto;
  margin-right: auto;
  width: 50px;
  border-radius: 30px;
   border: 2px solid #1e555c; 
  
  margin-top: 30px;
  margin-top: 30px;
  width: 200px;
  text-align: center;
  
} */

#note-label {
  font-size:0.7em;

  text-align: center;
  width: 200px;
  margin-top:10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom:10px;
}
/* .note-form textarea {
  height: 200px;
  width: 400px;
} */

.list-contents *{
margin-top:10px;
margin-bottom:10px;
}

.list-contents {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-evenly;
}

.visit-url {
  margin-bottom: 30px;
  /* margin-left: 10px;
  margin-right: 10px; */
  
  overflow-x: auto;
  /* text-align: center; */
}

/* .list-contents p{
  margin-left:auto;
  margin-right:auto;
  text-align: center;
} */

a {
  width: 70%;
}

.delete {
  color: white;
  margin-top: 20px;
  margin-bottom: 10px;
  background: #f15152;
  /* border: white; */
}

.submit-topic-btn{
  width:100px;
  margin-left:auto;
  margin-right:auto;
  margin-top:20px;
}


