* {
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;

  text-align: center;
   font-size: 20px;  

  color: black;

  text-decoration: none;
}

#root {
  height: 100%;
}
a:hover {
  color: #f15152;
}

.App {
  height: 100%;
  margin-top: 0;
  padding: 0px;

  margin-bottom: 0;
}

body {
  background: #edb183;
  margin: 0px;
  padding: 0px;
  height: 100%;
  width: 100%;
}

html {
  padding-top: 0px;

  height: 100%;
  width: 100%;
}

@media all and (min-width:768px){
  * {
    font-size:20px;
  }
}