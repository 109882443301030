header {
  margin-top:0px;
 position: static;
 margin-bottom: 100px;
 top:0px;
 right: 0px;
 left:0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-items: center;
  justify-content: center;
  background: #1e555c;
  margin-left:auto;
  margin-right:auto;
  /* font-size: 20px; */
  /* font-size: 45px; */
  /* padding-top: 20px; */
}
#header
 {
   padding:20px;
   margin-left: auto;
   margin-right: auto;
  background: #1e555c;
  color:white;
  font-family: "Coda Caption", sans-serif;
  font-size:20px
}


@media all and (min-width:768px) {
  #header {
    font-size: 20px;
    
  } 
} 
