.home-page-top{
    height:100%;
    font-size:45px;
    /* width: 60%; */
    width:100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
  /* justify-content: baseline; */
  justify-content: center;
     align-items: center;
    flex-wrap:wrap;
  padding-top: 0px;
    /* height: 90%; */
    
  }
  
  .fa-bookmark{
    margin-right: 10px;
  }
  
  .home-page{
    font-size:45px;
    margin-top:200px;
    margin-left:auto;
    margin-right:auto;
    flex-wrap: nowrap;
    width:80%;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    
  }
  /* .home-page *{
     font-size: 45px;
    /* font-weight:700; */
    /* font-weight: 900; */
    
  
  
  .home-left {
    font-size:45px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    /* margin-right: 100px; */
    text-align: left;
  }
  
  .home-right{
    font-size:45px;
      display:flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
  
  } 
  .home-bottom{
    margin-top:200px;
    font-size:45px;
  }
  
  
  
  .underline{
    text-decoration: underline;
    text-align: center;
  
  }
  .home-left p{
    
    margin-top: 0px;
    text-align:left;
  }
  
  
    
   
  
  @media all and (min-width:768px) {
    .home-page-top{
      flex-direction:row;
      align-items: flex-start;
    }
  
  
    /* .home-left p {
      font-size:35px;
      color:blue;
    } */
  .home-page *{
      font-size:35px
  }
  
  
  
  }