
.submit-form{
  display: flex;
    flex-direction: column;
    align-items: center;
  width:70vw;
  background: #f4d8cd;
  padding:20px;
  margin-left:auto;
  margin-right:auto;
  margin-bottom:50px;
  border-radius: 200px;
  box-shadow: 2px 2px 5px 5px gray;
}




@media all and (mid-width:768px){


  .submit-form {
  
  
    
    border: 1px solid white;
    
    
   
    width: 50vw;
    margin-top: 50px;
    
    
    
  }
  
  .submit-form input {
    border-radius: 100px;
    border: 2px solid #1e555c;
    margin: 30px;
    width: 200px;
    font-size: 1em;
  }
  
  .submit-form label {
    color: #1e555c;
  }
  
  .submit-topic-btn {
    background: #f15152;
  font-size: 0.7em;
  
  }

}