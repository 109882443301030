.Textarea {
  width: 30vw;
  border-radius: 10px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  font-size: 0.8em;
  text-align: center;
  height: 20vw;
  
  border: 2px solid #1e555c;
}


.Input,
.Select {
  border-radius: 100px;
  height: 30%;
  border: 2px solid #1e555c;
  display: block;
  font-size: 0.8em;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  text-align: left;
   width: 30vw;
  ;
  /* border-radius: 4px; */
}

.Input::placeholder,
.Textarea::placeholder {
  color: #ccc;
  font-size: 0.6em;
  text-align: center;
}

@media all and (min-width: 768px) {
  .Input::placeholder,
  .Textarea::placeholder {
    color: #ccc;
    font-size: 0.7em;
    text-align: center;
  }

  .Textarea {
    font-size: 1em;
  }

}

/* .Textarea {
    resize: vertical;
  }
    */
.Button {
  order: 1px solid #aaa;
  background-color: #eee;
  font-size: 1.1em;
  border-radius: 4px;
  padding: 8px 12px;
  font-weight: bold;
  border:#1e555c;
}

.Required {
  color: #ca1551;
  font-family: Arial;
  font-weight: bold;
}

.Section {
  margin-left: 20px;
  margin-right: 20px;
}
.Section--list {
  margin-left: 10px;
  margin-right: 10px;
}
